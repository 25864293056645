@use "../config/" as *;
.waf-squad {
    @extend %half-radius;
    @extend %m-4;
    @extend %p-4;
    @extend %pure-white-900-bg;
    .waf-head {
        border-bottom: .1rem solid var(--neutral-1000);
        @extend %pb-5;
        .head-wrap {
            // flex-wrap: wrap;
            @extend %relative;
            @extend %gap-4;
        }
        .title {
            height: auto;
            @extend %w-100;
        }
        .sub-title {
            @extend %secondary-800;
            @extend %uppercase;
            @extend %font-16-pb;
        }
        .filter-section {
            position: unset;
        }
    }
    .waf-body {
        @extend %mt-8;
    }
    .squad {
        &-group {
            &:not(:last-child) {
                border-bottom: .1rem solid var(--neutral-50);
                @extend %mb-8;
                @extend %pb-8;
            }
        }
        &-head {
            @extend %mb-12;
        }
        &-listing {
            gap: 4.5rem;
            flex-wrap: wrap;
            @extend %flex-column;
        }
        &-title {
            @extend %secondary-800;
            @extend %uppercase;
            @extend %font-16-pb;
        }
        &-item {
            border: .1rem solid var(--neutral-1000);
            @extend %p-3;
            @extend %relative;
            @extend %half-radius;
        }
        &-content {
            @extend %mt-3;
        }
        &-thumbnail {
            width: 6rem;
            height: 6rem;
            overflow: hidden;
            outline: .2rem solid var(--primary-300);
            margin-top: -4rem;
            @extend %secondary-50-bg;
            @extend %circle-radius;
        }
        &-name {
            .name {
                @extend %font-16-pr;
                @extend %capitalize;
                @extend %neutral-800;
                &.last-name {
                    font-weight: 700;
                }
            }
        }
        &-action {
            .btn-more {
                @extend %font-0;
                @extend %pos-bl;
                @extend %w-100;
                @extend %h-100;
            }
        }
    }
    .captain {
        &::before {
            content: 'C';
            width: 1.7rem;
            height: 1.7rem;
            border-radius: .2rem;
            @extend %primary-300;
            @extend %secondary-800-bg;
            @extend %font-12-pb;
            @extend %flex-c-c;
            @include position(var(--space-4), var(--space-4), null, null);
        }
    }
}
@include mq(col-md) {
    .waf-squad {
        margin-inline: 0;
        .waf-head {
            border-bottom: 0;
            padding-bottom: 0;
            .head-wrap {
                flex-wrap: wrap;
            }
        }
        .layout-wrapper {
            max-width: unset;
            width: 100%;
        }
        .filter-section {
            z-index: var(--z-squad-filter);
            .filter-wrap,
            .btn-more {
                display: none;
            }
            .more-filters {
                overflow: visible;
                height: auto;
                padding-block: var(--space-4);
                border-top: 0;
                padding: 0;
            }
        }
        .squad {
            &-listing {
                column-gap: var(--space-4);
                flex-direction: row;
            }
            &-item {
                width: calc(50% - var(--space-2));
            }
        }
    }
}
@include mq(col-lg) {
    .waf-squad {
        .layout-wrapper {
            max-width: var(--container-max-width);
        }
        .squad {
            &-item {
                width: calc(25% - var(--space-3));
            }
            &-name {
                .name {
                    font-size: 1.4rem;
                }
            }
        }
    }
}